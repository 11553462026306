import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import HeadingLayout from "../../../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import Form from "../../../../../components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, RequiredLabel } from "../../../../../components/base/fieldset";
import { Button } from "../../../../../components/base/button";
import { useForm } from "@tanstack/react-form";
import { Input } from "../../../../../components/base/input";
import { Api } from "../../../../../api/api";
import { toast } from "react-toastify";

/**
 * The properties for {@link CreateQuestionnaireTemplate}
 */
export type CreateQuestionnaireTemplateProps = {};

/**
 * Creation of questionnaire templates
 */
export default function CreateQuestionnaireTemplate(props: CreateQuestionnaireTemplateProps) {
    const [t] = useTranslation();

    const navigate = Route.useNavigate();

    const createForm = useForm({
        defaultValues: { name: "" },
        onSubmit: async ({ value, formApi }) => {
            const res = await Api.internal.questionnaires.templates.create({ name: value.name });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        navigate({
                            to: "/i/questionnaires/templates/$questionnaireId",
                            params: { questionnaireId: res.value.uuid },
                        });
                    } else {
                        if (res.error.name) {
                            formApi.setFieldMeta("name", (meta) => {
                                meta.errors.push(t("internal.create-questionnaire-template.error.name-in-use"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });

    return (
        <HeadingLayout heading={t("internal.create-questionnaire-template.heading.create-template")}>
            <Form onSubmit={createForm.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <createForm.Field name={"name"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>
                                        {t("internal.create-questionnaire-template.label.template-name")}
                                    </RequiredLabel>
                                    <Input
                                        autoFocus={true}
                                        required={true}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </createForm.Field>

                        <Button color={"blue"} type={"submit"}>
                            {t("button.create")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/templates/create")({
    component: CreateQuestionnaireTemplate,
});
