/* eslint-disable */
// @ts-nocheck

import { Base64 } from "js-base64";
import { Err, Ok, Result } from "../utils/result";
import { ApiError, parseError } from "./error";
import CONSOLE from "../utils/console";
import { UUID } from "./api";
import { toast } from "react-toastify";
import i18n from "../i18n";

/** The error we can receive */
type BrowserError = {
    /** Was the request successful */
    success: false;
    /** The error message */
    message: string;
};

const HEADERS = new Headers([
    ["Accept", "application/json"],
    ["Content-Type", "application/json"],
]);

/**
 * The parameters for authentication with a key
 */
export type AuthenticateUsages = {
    passwordless: {
        params: {
            /** The mail of the user */
            mail: string;
        };
    };
    mfa: {
        params: { undefined };
    };
    sudo: {
        params: { undefined };
    };
};

/**
 * The authentication request with a key
 *
 * @param params The parameters to use. See more in their documentation
 *
 * @returns A promise with a wrapper Result and converted errors
 */
export async function authenticate<K extends keyof AuthenticateUsages>(
    type: K,
    params: AuthenticateUsages[K]["params"],
): Promise<Result<null, ApiError | BrowserError>> {
    let res;
    res = await fetch(
        type === "passwordless"
            ? "/api/frontend/v1/common/auth/login-webauthn"
            : type === "mfa"
              ? "/api/frontend/v1/common/auth/verify-webauthn"
              : "/api/frontend/v1/common/sudo/webauthn",
        {
            method: "post",
            headers: HEADERS,
            body: type === "passwordless" ? JSON.stringify({ mail: params.mail }) : undefined,
        },
    );
    if (res.status !== 200) {
        return new Err(await parseError(res));
    }

    const challenge = await res.json();

    let publicKey;

    if (challenge.result !== "Ok") {
        toast.error(i18n.t("toast.unknown-error"));
        CONSOLE.error(challenge);
        return { success: false, message: "unknown error" };
    }

    publicKey = challenge.value.publicKey;
    CONSOLE.debug("Received challenge from server", challenge);

    const credential = await navigator.credentials.get({
        publicKey: {
            rpId: publicKey.rpId,
            timeout: publicKey.timeout,
            userVerification: publicKey.userVerification,
            challenge: Base64.toUint8Array(publicKey.challenge),
            allowCredentials: publicKey.allowCredentials.map((c) => {
                return {
                    id: Base64.toUint8Array(c.id),
                    type: c.type,
                };
            }),
        },
    });
    CONSOLE.debug("Received credential from browser", credential);
    if (credential === null) {
        return new Err({
            success: false,
            message: "Getting credentials from browser failed",
        });
    }

    res = await fetch(
        type === "sudo"
            ? "/api/frontend/v1/common/sudo/complete-webauthn"
            : "/api/frontend/v1/common/auth/complete-webauthn",
        {
            method: "post",
            body: JSON.stringify({
                id: Base64.fromUint8Array(new Uint8Array(credential.id), true),
                rawId: Base64.fromUint8Array(new Uint8Array(credential.rawId), true),
                response: {
                    authenticatorData: Base64.fromUint8Array(
                        new Uint8Array(credential.response.authenticatorData),
                        true,
                    ),
                    clientDataJSON: Base64.fromUint8Array(new Uint8Array(credential.response.clientDataJSON), true),
                    signature: Base64.fromUint8Array(new Uint8Array(credential.response.signature), true),
                    userHandle: credential.response.userHandle,
                },
                type: credential.type,
            }),
            headers: HEADERS,
        },
    );
    if (res.status === 200) {
        return new Ok(null);
    } else {
        return new Err(await parseError(res));
    }
}

/**
 * The parameters for the registration
 */
type RegisterKeyUsages = {
    user: {
        params: {
            /** Whether the user can use this key for password-less registration */
            canLogin: boolean;
            /** The label of the key */
            label: string;
        };
        error: "missing-sudo" | "missing-password" | "unknown";
    };
    reset: {
        params: {
            /** Whether the user can use this key for password-less registration */
            canLogin: boolean;
            /** The label of the key */
            label: string;
        };
        error: "missing-password" | "unknown";
    };
    invite: {
        params: {
            /** The uuid of the invite_ */
            uuid: UUID;
            /** The label of the key */
            label: string;
        };
        error: "unknown";
    };
};

/**
 * Register a webauthn key
 *
 * @param params Whether to register the key in the normal user context or as part of the invite_
 *
 * @returns A promise whether the request was successful
 */
export async function registerKey<K extends keyof RegisterKeyUsages>(
    type: K,
    params: RegisterKeyUsages[K]["params"],
): Promise<Result<Result<null, RegisterKeyUsages[K]["error"]>, ApiError | BrowserError>> {
    let res;
    res = await fetch(
        type === "user"
            ? "/api/frontend/v1/common/users/me/webauthn"
            : type === "reset"
              ? "/api/frontend/v1/common/user-resets/webauthn"
              : `/api/frontend/v1/common/user-invites/accept/${params.uuid}/with-webauthn`,
        {
            method: "post",
            headers: HEADERS,
            body: JSON.stringify(
                type === "user" || type === "reset"
                    ? {
                          can_login: params.canLogin,
                          label: params.label,
                      }
                    : { label: params.label },
            ),
        },
    );
    if (res.status !== 200) {
        return new Err(await parseError(res));
    }

    const req = await res.json();
    if (req.result === "Err") {
        if (req.error.missing_password) {
            return new Ok(new Err("missing-password"));
        }
        if (req.error.missing_sudo) {
            return new Ok(new Err("missing-sudo"));
        }
        return new Ok(new Err("unknown"));
    }

    const challenge = req.value;
    const { publicKey } = challenge;
    CONSOLE.debug("Received challenge from server", challenge);

    const credential = await navigator.credentials.create({
        publicKey: {
            user: {
                id: Base64.toUint8Array(publicKey.user.id),
                name: publicKey.user.name,
                displayName: publicKey.user.displayName,
            },
            attestation: publicKey.attestation,
            rp: publicKey.rp,
            challenge: Base64.toUint8Array(publicKey.challenge),
            extensions: publicKey.extensions,
            timeout: publicKey.timeout,
            excludeCredentials:
                publicKey.excludeCredentials !== undefined
                    ? publicKey.excludeCredentials.map((c) => {
                          return {
                              id: Base64.toUint8Array(c.id),
                              type: c.type,
                          };
                      })
                    : [],
            pubKeyCredParams: publicKey.pubKeyCredParams,
            authenticatorSelection: publicKey.authenticatorSelection,
        },
    });
    CONSOLE.debug("Created credential from browser", credential);
    if (credential === null) {
        return new Err({
            success: false,
            message: "Requesting new credentials from browser failed",
        });
    }

    res = await fetch(
        type === "user"
            ? "/api/frontend/v1/common/users/me/complete-webauthn"
            : type === "reset"
              ? "/api/frontend/v1/common/user-resets/complete-webauthn"
              : "/api/frontend/v1/common/user-invites/complete-webauthn",
        {
            method: "post",
            body: JSON.stringify({
                id: credential.id,
                rawId: Base64.fromUint8Array(new Uint8Array(credential.rawId), true),
                response: {
                    attestationObject: Base64.fromUint8Array(
                        new Uint8Array(credential.response.attestationObject),
                        true,
                    ),
                    clientDataJSON: Base64.fromUint8Array(new Uint8Array(credential.response.clientDataJSON), true),
                },
                type: credential.type,
            }),
            headers: HEADERS,
        },
    );
    if (res.status !== 200) {
        return new Err(await parseError(res));
    } else {
        return new Ok(new Ok(null));
    }
}
