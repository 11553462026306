import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { CustomerFullPentestProject } from "src/api/generated";
import { Subheading } from "src/components/base/heading";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import CUSTOMER_TASKS_CONTEXT from "src/context/tasks-customer";
import ContactCard from "src/components/contact-card";
import { Text } from "src/components/base/text";
import { sortDates } from "src/utils/sorter";

/**
 * The properties for {@link PentestProject}
 */
export type PentestProjectProps = {};

/**
 * A pentesting project
 */
export default function PentestProject(props: PentestProjectProps) {
    const { projectId } = Route.useParams();
    const [t] = useTranslation();

    const [project, setProject] = React.useState<CustomerFullPentestProject>();
    const { tasks } = React.useContext(CUSTOMER_TASKS_CONTEXT);

    useEffect(() => {
        Api.customer.projects.pentest.get(projectId).then((res) =>
            res.match(
                (opt) => setProject(opt.optional),
                (err) => toast.error(err.message),
            ),
        );
    }, [projectId]);

    return (
        project && (
            <div className={"grid grid-cols-1 gap-12"}>
                <div className={"col-span-2 flex flex-col gap-12"}>
                    <div className={"flex gap-6"}>
                        <div className={"flex flex-col gap-3"}>
                            <Subheading>{t("customer.pentest-project.heading.your-technical-contact")}</Subheading>
                            <ContactCard
                                hideBadges={true}
                                className={"w-fit"}
                                contact={{
                                    ...project.technical_lead,
                                    name: project.technical_lead.display_name,
                                    user: project.technical_lead,
                                }}
                            />
                        </div>
                        <div className={"flex flex-col gap-3"}>
                            <Subheading>{t("customer.pentest-project.heading.your-pm-contact")}</Subheading>
                            <ContactCard
                                hideBadges={true}
                                className={"w-fit"}
                                contact={{
                                    ...project.customer.project_manager,
                                    name: project.customer.project_manager.display_name,
                                    user: project.customer.project_manager,
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={"flex flex-col gap-3"}>
                    <Subheading className={"col-span-6"}>{t("customer.pentest-project.heading.todos")}</Subheading>
                    {tasks.length === 0 ? (
                        <Text>{t("customer.pentest-project.description.no-tasks-left")}</Text>
                    ) : (
                        <div className={"col-span-6 flex flex-col gap-3"}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader>{t("customer.pentest-project.label.task")}</TableHeader>
                                        <TableHeader>{t("customer.pentest-project.label.deadline")}</TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tasks
                                        .filter((x) => x.project?.uuid === projectId)
                                        .sort((a, b) =>
                                            sortDates(
                                                a.deadline ? new Date(a.deadline) : undefined,
                                                b.deadline ? new Date(b.deadline) : undefined,
                                            ),
                                        )
                                        .map((task) =>
                                            task.data.kind === "FillPentestTargetsV0" ? (
                                                <TableRow
                                                    key={task.uuid}
                                                    href={"/c/projects/pentests/$projectId/targets/$targetId"}
                                                    params={{
                                                        projectId: task.project?.uuid,
                                                        targetId: task.data.pentest_targets,
                                                    }}
                                                >
                                                    <TableCell>
                                                        {task.deadline && new Date(task.deadline).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>{t("tasks.label.fill-pentest-targets")}</TableCell>
                                                </TableRow>
                                            ) : task.data.kind === "FillQuestionnaireV0" ? (
                                                <TableRow
                                                    key={task.uuid}
                                                    href={
                                                        "/c/projects/pentests/$projectId/questionnaire/$questionnaireId"
                                                    }
                                                    params={{
                                                        projectId: task.project?.uuid,
                                                        questionnaireId: task.data.questionnaire,
                                                    }}
                                                >
                                                    <TableCell>
                                                        {task.deadline && new Date(task.deadline).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {task.data.usage === "AssessmentOrganizational"
                                                            ? t("tasks.label.fill-questionnaire-organizational")
                                                            : t("tasks.label.fill-questionnaire-tech")}
                                                    </TableCell>
                                                </TableRow>
                                            ) : task.data.kind === "AssignProjectContactsV0" ? (
                                                <TableRow
                                                    key={task.uuid}
                                                    href={"/c/forms/tasks/assign-project-contacts"}
                                                    search={{ projectId: task.project?.uuid, taskId: task.uuid }}
                                                >
                                                    <TableCell>
                                                        {task.deadline && new Date(task.deadline).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>{t("tasks.label.assign-project-contacts")}</TableCell>
                                                </TableRow>
                                            ) : (
                                                <TableRow key={task.uuid}>
                                                    <TableCell>
                                                        {task.deadline && new Date(task.deadline).toLocaleDateString()}
                                                    </TableCell>
                                                    <TableCell>{JSON.stringify(task.data)}</TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

export const Route = createFileRoute("/_customer/c/projects/pentests/$projectId/_pentest/general")({
    component: PentestProject,
});
