import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import HeadingLayout from "src/components/base/heading-layout";
import { Checkbox, CheckboxGroup } from "src/components/base/checkbox";

/**
 * The properties for {@link EnterDebriefResults}
 */
export type EnterDebriefResultsProps = {};

/**
 * The form to enter dialog results
 */
export default function EnterDebriefResults(props: EnterDebriefResultsProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();

    const form = useForm({
        defaultValues: {
            presentationDate: "",
            skipMgmtPresentation: false,
            upsellOptions: "",
            notes: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setDebriefResultsV0(taskId, {
                presentation_date: value.skipMgmtPresentation
                    ? { state: "Canceled" }
                    : value.presentationDate === ""
                      ? undefined
                      : {
                            state: "Scheduled",
                            date: new Date(value.presentationDate).toISOString(),
                        },
                upsale_options: value.upsellOptions,
                notes: value.notes,
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        window.history.back();
                    } else {
                        if (res.error.presentation_date) {
                            formApi.setFieldMeta("presentationDate", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = form.useStore((store) => store.isDirty);
    const skipMgmtPresentation = form.useStore((store) => store.values.skipMgmtPresentation);

    return (
        <HeadingLayout heading={t("internal.enter-debrief-results.heading")}>
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"presentationDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.mgmt-presentation-date")}</Label>
                                    <Input
                                        disabled={skipMgmtPresentation}
                                        autoFocus={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"skipMgmtPresentation"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.skip-mgmt-presentation")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"upsellOptions"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.upsell-options")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            <BlockUnsavedChanges condition={isDirty} />
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-debrief-results-v0")({
    component: EnterDebriefResults,
});
