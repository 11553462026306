import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import HeadingLayout from "../../../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Api, UUID } from "../../../../../api/api";
import { FullQuestion } from "../../../../../api/generated";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../../components/base/table";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "../../../../../components/base/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../../../components/base/button";
import { Input } from "../../../../../components/base/input";
import { Field } from "../../../../../components/base/fieldset";
import { Text } from "../../../../../components/base/text";
import QuestionTypeIcon from "../../../../../components/questionnaire/question-type-icon";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "../../../../../components/base/dialog";

/**
 * The properties for {@link QuestionnaireQuestions}
 */
export type QuestionnaireQuestionsProps = {};

/**
 * The overview of all questionnaire questions
 */
export default function QuestionnaireQuestions(props: QuestionnaireQuestionsProps) {
    const [t] = useTranslation();

    const [questions, setQuestions] = React.useState<Array<FullQuestion>>([]);
    const [questionQuery, setQuestionQuery] = React.useState("");
    const [deleteQuestion, setDeleteQuestion] = React.useState<FullQuestion>();

    /**
     * Delete a specific question
     *
     * @param uuid The uuid of the question to remove
     */
    const deleteQuestionApi = (uuid: UUID) => {
        Api.internal.questionnaires.questions.delete(uuid).then((res) =>
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        refreshQuestions();
                    } else {
                        toast.error(t("internal.questionnaire-questions.error.in-use"));
                    }
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Helper method to refresh the question list
     */
    const refreshQuestions = () => {
        Api.internal.questionnaires.questions.all().then((res) =>
            res.match(
                (list) => setQuestions(list.list),
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => {
        refreshQuestions();
    }, []);

    const filtered =
        questionQuery === ""
            ? questions
            : questions.filter((q) => q.name.toLowerCase().includes(questionQuery.toLowerCase()));

    return (
        <HeadingLayout
            className={"gap-8"}
            heading={t("internal.questionnaire-questions.heading.questions-overview")}
            headingChildren={
                <Button href={"/i/questionnaires/questions/create"}>
                    {t("internal.questionnaire-questions.button.create-question")}
                </Button>
            }
        >
            <Field className={"flex w-fit flex-col gap-3"}>
                <Text className={"!text-white"}>{t("label.search")}</Text>
                <Input value={questionQuery} onChange={(e) => setQuestionQuery(e.target.value)} />
            </Field>

            <Table dense={true}>
                <TableHead>
                    <TableRow>
                        <TableHeader>{t("internal.questionnaire-questions.label.question-name")}</TableHeader>
                        <TableHeader>{t("internal.questionnaire-questions.label.question-type")}</TableHeader>
                        <TableHeader className={"max-lg:hidden"}>{t("label.created-at")}</TableHeader>
                        <TableHeader className={"max-lg:hidden"}>{t("label.created-by")}</TableHeader>
                        <TableHeader className={"w-0"}>
                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filtered.map((question) => (
                        <TableRow
                            key={question.uuid}
                            href={"/i/questionnaires/questions/$questionId"}
                            params={{ questionId: question.uuid }}
                        >
                            <TableCell>
                                <span
                                    className={"block w-[50ch] overflow-hidden text-ellipsis lg:w-[30ch] xl:w-[50ch]"}
                                >
                                    {question.name}
                                </span>
                            </TableCell>
                            <TableCell>
                                <QuestionTypeIcon type={question.data.kind} className={"size-6"} />
                            </TableCell>
                            <TableCell className={"max-lg:hidden"}>
                                {new Date(question.created.at).toLocaleDateString()}
                            </TableCell>
                            <TableCell className={"max-lg:hidden"}>{question.created.by?.display_name}</TableCell>
                            <TableCell>
                                <Dropdown>
                                    <DropdownButton plain={true}>
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor={"bottom end"}>
                                        <DropdownItem onClick={() => setDeleteQuestion(question)}>
                                            <DropdownLabel>{t("button.delete")}</DropdownLabel>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {deleteQuestion && (
                <Dialog open={true} onClose={() => setDeleteQuestion(undefined)}>
                    <DialogBody>
                        <DialogTitle>
                            {t("dialog.heading.delete-question", { question: deleteQuestion.name })}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setDeleteQuestion(undefined)}>{t("button.no")}</Button>
                            <Button
                                color={"red"}
                                onClick={() => {
                                    deleteQuestionApi(deleteQuestion?.uuid);
                                    setDeleteQuestion(undefined);
                                }}
                            >
                                {t("button.yes")}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </Dialog>
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/questions/")({
    component: QuestionnaireQuestions,
});
