import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import HeadingLayout from "../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/base/button";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FullUser } from "../../../api/generated";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/base/table";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "../../../components/base/dropdown";
import { Api } from "../../../api/api";
import { toast } from "react-toastify";
import { Badge } from "../../../components/base/badge";
import { kebabCase } from "lodash";
import DialogUserGroups, { DialogUserGroupsAdditional } from "../../../components/dialogs/admin/dialog-user-groups";
import CreateUserDialog from "../../../components/dialogs/admin/dialog-create-user";

/**
 * The properties for {@link UserManagement}
 */
export type UserManagementProps = {};

/**
 * The user management
 */
export default function UserManagement(props: UserManagementProps) {
    const [t] = useTranslation();

    const [users, setUsers] = React.useState<Array<FullUser>>([]);

    const [openNewUserDialog, setOpenNewUserDialog] = React.useState(false);
    // The internal groups of the user that should be edited
    const [openEditGroupsDialog, setOpenEditGroupsDialog] = React.useState<DialogUserGroupsAdditional>();

    const refreshUsers = () => {
        Api.admin.users.all().then((res) =>
            res.match(
                (users) => {
                    users.list.sort((a, b) => a.display_name.localeCompare(b.display_name));
                    setUsers(users.list);
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => refreshUsers(), [openNewUserDialog, openEditGroupsDialog]);

    return (
        <>
            <HeadingLayout
                heading={t("User Management")}
                headingChildren={
                    <Button type={"button"} onClick={() => setOpenNewUserDialog(true)}>
                        <PlusIcon />
                        {t("Create User")}
                    </Button>
                }
            >
                <Table className={"text-zinc-800 dark:text-zinc-200"} dense={true}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{t("Name")}</TableHeader>
                            <TableHeader>{t("Mail")}</TableHeader>
                            <TableHeader>{t("Role")}</TableHeader>
                            <TableHeader>{t("Groups")}</TableHeader>
                            <TableHeader className={"relative w-0"}>
                                <span className={"sr-only"}>{t("Action")}</span>
                            </TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.uuid}>
                                <TableCell>{user.display_name}</TableCell>
                                <TableCell>{user.mail}</TableCell>
                                <TableCell>{t(user.permissions.role)}</TableCell>
                                <TableCell>
                                    {user.permissions.role === "Internal" ? (
                                        <div className={"flex flex-wrap gap-1"}>
                                            {Object.entries(user.permissions.groups).map(
                                                ([key, value]) =>
                                                    value && (
                                                        <Badge key={key} color={"blue"}>
                                                            {t(`label.${kebabCase(key)}`)}
                                                        </Badge>
                                                    ),
                                            )}
                                        </div>
                                    ) : undefined}
                                </TableCell>
                                <TableCell>
                                    <Dropdown>
                                        <DropdownButton plain={true} aria-label={t("Open actions")}>
                                            <EllipsisVerticalIcon />
                                        </DropdownButton>
                                        <DropdownMenu anchor={"bottom end"}>
                                            {user.permissions.role === "Internal" && (
                                                <DropdownItem
                                                    onClick={() => {
                                                        user.permissions.role === "Internal" &&
                                                            setOpenEditGroupsDialog({
                                                                uuid: user.uuid,
                                                                groups: user.permissions.groups,
                                                            });
                                                    }}
                                                >
                                                    <DropdownLabel>{t("Change groups")}</DropdownLabel>
                                                </DropdownItem>
                                            )}
                                            {user.permissions.role === "Customer" && (
                                                <DropdownItem
                                                    onClick={() => {
                                                        Api.admin.users.resetCredentials(user.uuid).then((res) =>
                                                            res.match(
                                                                (res) => {
                                                                    if (res.result === "Ok") {
                                                                        navigator.clipboard
                                                                            .writeText(res.value.link)
                                                                            .then(() =>
                                                                                toast.success("copied to clipboard"),
                                                                            );
                                                                    } else {
                                                                        if (res.error.not_local) {
                                                                            toast.error(
                                                                                "Can not reset the credentials of a non-local user",
                                                                            );
                                                                        }
                                                                    }
                                                                },
                                                                (err) => toast.error(err.message),
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    <DropdownLabel>{t("Reset credentials")}</DropdownLabel>
                                                </DropdownItem>
                                            )}
                                            <DropdownItem>
                                                <DropdownLabel>{t("Delete")}</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </HeadingLayout>

            {openEditGroupsDialog && (
                <DialogUserGroups
                    open={true}
                    onClose={() => setOpenEditGroupsDialog(undefined)}
                    {...openEditGroupsDialog}
                />
            )}

            {openNewUserDialog && (
                <CreateUserDialog open={openNewUserDialog} onClose={() => setOpenNewUserDialog(false)} />
            )}
        </>
    );
}

export const Route = createFileRoute("/_admin/a/user")({
    component: () => <UserManagement />,
});
