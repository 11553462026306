import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HeadingLayout from "../../../../../components/base/heading-layout";
import { Button } from "../../../../../components/base/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../../components/base/table";
import { SimpleQuestionGroup } from "../../../../../api/generated";
import { Api } from "../../../../../api/api";
import { toast } from "react-toastify";
import { Field } from "@headlessui/react";
import { Input } from "../../../../../components/base/input";
import { Text } from "../../../../../components/base/text";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "../../../../../components/base/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Badge } from "../../../../../components/base/badge";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "../../../../../components/base/dialog";

/**
 * The properties for {@link QuestionnaireGroups}
 */
export type QuestionnaireGroupsProps = {};

/**
 * The overview of all questionnaire groups
 */
export default function QuestionnaireGroups(props: QuestionnaireGroupsProps) {
    const [t] = useTranslation();

    const [groups, setGroups] = React.useState<Array<SimpleQuestionGroup>>([]);
    const [groupQuery, setGroupQuery] = React.useState("");
    const [deleteGroup, setDeleteGroup] = React.useState<SimpleQuestionGroup>();

    /**
     * Helper function to refresh the groups
     */
    const refreshGroups = () => {
        Api.internal.questionnaires.groups.all().then((res) =>
            res.match(
                (list) => setGroups(list.list),
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => {
        refreshGroups();
    }, []);

    const filtered =
        groupQuery === "" ? groups : groups.filter((g) => g.name.toLowerCase().includes(groupQuery.toLowerCase()));

    return (
        <HeadingLayout
            heading={t("internal.questionnaire-groups.heading.group-overview")}
            headingDescription={t("internal.questionnaire-groups.description.questionnaire-groups")}
            headingChildren={
                <Button href={"/i/questionnaires/groups/create"}>
                    {t("internal.questionnaire-groups.button.create-group")}
                </Button>
            }
        >
            <Field className={"flex w-fit flex-col gap-3"}>
                <Text className={"!text-white"}>{t("label.search")}</Text>
                <Input value={groupQuery} onChange={(e) => setGroupQuery(e.target.value)} />
            </Field>

            <Table dense={true}>
                <TableHead>
                    <TableRow>
                        <TableHeader>{t("label.questionnaire-group-name")}</TableHeader>
                        <TableHeader>{t("label.no-of-questions")}</TableHeader>
                        <TableHeader>{t("label.no-of-uses")}</TableHeader>
                        <TableHeader className={"max-xl:hidden"}>{t("label.created-at")}</TableHeader>
                        <TableHeader className={"max-xl:hidden"}>{t("label.created-by")}</TableHeader>
                        <TableHeader className={"w-0"}>
                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filtered.map((group) => (
                        <TableRow
                            key={group.uuid}
                            href={"/i/questionnaires/groups/$groupId"}
                            params={{ groupId: group.uuid }}
                        >
                            <TableCell className={"max-w-[40ch] overflow-hidden text-ellipsis"}>{group.name}</TableCell>
                            <TableCell>{group.questions}</TableCell>
                            <TableCell>
                                <Badge color={"yellow"}>{t("label.coming-soon")}</Badge>
                            </TableCell>
                            <TableCell className={"max-xl:hidden"}>
                                {new Date(group.created.at).toLocaleDateString()}
                            </TableCell>
                            <TableCell className={"max-xl:hidden"}>{group.created.by?.display_name}</TableCell>
                            <TableCell>
                                <Dropdown>
                                    <DropdownButton plain={true}>
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => setDeleteGroup(group)}>
                                            <DropdownLabel>{t("button.delete")}</DropdownLabel>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {deleteGroup && (
                <Dialog open={true} onClose={() => setDeleteGroup(undefined)}>
                    <DialogBody>
                        <DialogTitle>{t("dialog.heading.delete-group", { group: deleteGroup.name })}</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setDeleteGroup(undefined)}>{t("button.no")}</Button>
                            <Button
                                color={"red"}
                                onClick={() => {
                                    Api.internal.questionnaires.groups.delete(deleteGroup.uuid).then((res) =>
                                        res.match(
                                            (res) => {
                                                if (res.result === "Ok") {
                                                    refreshGroups();
                                                }
                                            },
                                            (err) => toast.error(err.message),
                                        ),
                                    );
                                    setDeleteGroup(undefined);
                                }}
                            >
                                {t("button.yes")}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </Dialog>
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/questionnaires/groups/")({
    component: QuestionnaireGroups,
});
