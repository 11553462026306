import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { FullPentestProject, InternalUser, SimpleUser } from "src/api/generated";
import Form from "src/components/base/form";
import { Field, FieldGroup, Fieldset, Label, Legend } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { ChevronLeftIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useForm } from "@tanstack/react-form";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";

/**
 * The properties for {@link EditTechnicalAssistants}
 */
export type EditTechnicalAssistantsProps = {};

/**
 * Update technical assistants in pentest project
 */
export default function EditTechnicalAssistants(props: EditTechnicalAssistantsProps) {
    const [t] = useTranslation();

    const { obj, reset } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as FullPentestProject;

    const [internalList, setInternalList] = React.useState<Array<InternalUser>>([]);
    const [techAssistant, setTechAssistant] = React.useState<SimpleUser | null>(null);
    const [techAssistantQuery, setTechAssistantQuery] = React.useState("");
    const [techAssistants, setTechAssistants] = React.useState<Array<SimpleUser>>(project.technical_assistants);

    const techs = internalList.filter((c) => c.groups.technical);
    const filteredTechAssistants =
        techAssistantQuery === ""
            ? techs
                  .filter((user) => !techAssistants.some((ta) => ta.uuid === user.uuid))
                  .filter((user) => user.uuid !== project.technical_lead.uuid)
            : techs
                  .filter((user) => !techAssistants.some((ta) => ta.uuid === user.uuid))
                  .filter((user) => user.display_name.toLowerCase().includes(techAssistantQuery.toLowerCase()));

    useEffect(() => {
        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setInternalList(list.list),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const editForm = useForm({
        defaultValues: {
            techAssistant: techAssistants,
        },
        onSubmit: async ({ value, formApi }) => {
            const update = value.techAssistant.map((ta) => {
                return ta.uuid;
            });
            const res = await Api.internal.projects.pentest.update(project.uuid, {
                technical_assistant: update,
            });

            if (res !== undefined) {
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            reset();
                            history.back();
                        } else {
                            if (res.error.technical_assistant) {
                                formApi.setFieldMeta("techAssistant", (meta) => {
                                    meta.errors.push(t("error.invalid-ta"));
                                    return meta;
                                });
                            }
                        }
                    },
                    (err) => toast.error(err.message),
                );
            }
        },
    });

    return (
        <Form onSubmit={editForm.handleSubmit} className={"max-w-lg"}>
            <Fieldset>
                <div className={"flex items-center gap-3"}>
                    <Button plain={true} onClick={() => window.history.back()} params={{ projectId: obj.uuid }}>
                        <ChevronLeftIcon />
                    </Button>
                    <Legend>{t("label.edit-ta")}</Legend>
                </div>

                <FieldGroup>
                    <Field>
                        <div className={"flex items-center gap-3"}>
                            <Combobox
                                value={techAssistant}
                                onChange={(ta) => setTechAssistant(ta)}
                                onClose={() => setTechAssistantQuery("")}
                                queryDisplay={(ta) => (ta ? ta.display_name : "")}
                                onQueryChange={(query) => setTechAssistantQuery(query)}
                            >
                                {filteredTechAssistants.map((ta) => (
                                    <ComboboxOption value={ta}>
                                        <Label>{ta.display_name}</Label>
                                    </ComboboxOption>
                                ))}
                            </Combobox>
                            <Button
                                disabled={!techAssistant}
                                onClick={() => {
                                    techAssistant && setTechAssistants((prev) => [...prev, techAssistant]);
                                    setTechAssistant(null);
                                    setTechAssistantQuery("");
                                }}
                            >
                                {t("button.add")}
                            </Button>
                        </div>
                        {techAssistants.length !== 0 && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader>{t("label.name")}</TableHeader>
                                        <TableHeader className={"w-0"}>
                                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                        </TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {techAssistants.map((ta) => (
                                        <TableRow key={ta.uuid}>
                                            <TableCell>{ta.display_name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    plain={true}
                                                    onClick={() =>
                                                        setTechAssistants((prev) => [
                                                            ...prev.filter((user) => user.uuid !== ta.uuid),
                                                        ])
                                                    }
                                                >
                                                    <TrashIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </Field>
                    <Button type={"submit"} color={"blue"}>
                        {t("button.save")}
                    </Button>
                </FieldGroup>
            </Fieldset>
        </Form>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/general/edit-ta")({
    component: EditTechnicalAssistants,
});
