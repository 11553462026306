import React, { useEffect } from "react";
import HeadingLayout from "../../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Field, FieldGroup, Fieldset, Label, Legend } from "../../../../components/base/fieldset";
import { Button } from "../../../../components/base/button";
import { Divider } from "../../../../components/base/divider";
import { Api } from "../../../../api/api";
import { toast } from "react-toastify";
import { Text } from "../../../../components/base/text";
import { Input } from "../../../../components/base/input";
import CONSOLE from "../../../../utils/console";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { createFileRoute } from "@tanstack/react-router";

/**
 * The properties for {@link MailSettings}
 */
export type MailSettingsProps = {};

/**
 * The mail settings for the site
 */
export default function MailSettings(props: MailSettingsProps) {
    const [t] = useTranslation("admin-settings-mail");

    const [host, setHost] = React.useState("");
    const [port, setPort] = React.useState(465);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [fromMail, setFromMail] = React.useState("");
    const [fromName, setFromName] = React.useState("");

    const [showPassword, setShowPassword] = React.useState(false);

    const [testReceiver, setTestReceiver] = React.useState("");

    const fetchSettings = () => {
        Api.admin.mailSettings.get().then((res) =>
            res.match(
                (ok) => {
                    const settings = ok.optional;
                    if (settings === undefined) {
                        CONSOLE.debug("No settings found on server");
                        return;
                    }

                    setHost(settings.host);
                    setPort(settings.port);
                    setUsername(settings.username);
                    setPassword(settings.password);
                    setFromName(settings.from_name);
                    setFromMail(settings.from_mail);
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    const saveSettings = () => {
        Api.admin.mailSettings
            .set({
                host,
                port,
                username,
                password,
                from_mail: fromMail,
                from_name: fromName,
            })
            .then((res) => {
                res.match(
                    () => toast.success(t("Settings saved")),
                    (err) => toast.error(err.message),
                );
            });
    };

    const sendTestMail = () => {
        const toastId = toast.loading(t("Sending testmail"));

        Api.admin.mailSettings.test(testReceiver).then((res) =>
            res.match(
                (_) => {
                    toast.update(toastId, {
                        render: t("Testmail was sent"),
                        type: "success",
                        autoClose: 1500,
                        isLoading: false,
                    });
                },
                (err) => {
                    toast.update(toastId, { render: err.message, type: "error", autoClose: 1500, isLoading: false });
                },
            ),
        );
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    return (
        <>
            <HeadingLayout heading={t("Mail settings")}>
                <form
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveSettings();
                    }}
                >
                    <Fieldset>
                        <FieldGroup>
                            <Legend>{t("Network")}</Legend>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("Host")}</Label>
                                    <Text>{t("The address the mail server is reachable")}</Text>
                                </span>
                                <Input value={host} onChange={(e) => setHost(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("Port")}</Label>
                                    <Text>{t("The SMTP port of the mail server")}</Text>
                                </span>
                                <Input
                                    value={port}
                                    onChange={(e) => setPort(parseInt(e.target.value))}
                                    required={true}
                                    type={"number"}
                                />
                            </Field>

                            <Divider />

                            <Legend>{t("Login")}</Legend>

                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("Username")}</Label>
                                    <Text>
                                        {t("The username that is be used for authentication with the mail server")}
                                    </Text>
                                </span>
                                <Input value={username} onChange={(e) => setUsername(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("Password")}</Label>
                                    <Text>
                                        {t("The password that is used for authentication with the mail server")}
                                    </Text>
                                </span>
                                <span className={"flex gap-3"}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? "text" : "password"}
                                        required={true}
                                    />

                                    <Button
                                        plain={true}
                                        onClick={() => {
                                            setShowPassword((prev) => !prev);
                                        }}
                                    >
                                        {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                                    </Button>
                                </span>
                            </Field>

                            <Divider />

                            <Legend>{t("Sender")}</Legend>

                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("From name")}</Label>
                                    <Text>{t("The name that should be displayed as sender")}</Text>
                                </span>
                                <Input value={fromName} onChange={(e) => setFromName(e.target.value)} required={true} />
                            </Field>
                            <Field className={"grid grid-cols-1 items-start gap-3 sm:grid-cols-2"}>
                                <span>
                                    <Label>{t("From mail")}</Label>
                                    <Text>{t("The mail that should be used as sender")}</Text>
                                </span>
                                <Input
                                    value={fromMail}
                                    onChange={(e) => setFromMail(e.target.value)}
                                    required={true}
                                    type={"email"}
                                />
                            </Field>
                            <Divider />
                            <div className={"flex justify-end gap-6"}>
                                <Button type={"submit"} color={"blue"}>
                                    {t("Save Settings")}
                                </Button>
                            </div>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </HeadingLayout>
            <HeadingLayout className={"mt-12"} heading={t("Test mail settings")}>
                <form
                    method={"post"}
                    onSubmit={(e) => {
                        e.preventDefault();
                        sendTestMail();
                    }}
                >
                    <Fieldset className={"max-w-2xl"}>
                        <FieldGroup>
                            <Field>
                                <Label>{t("Receiver")}</Label>
                                <Text>{t("The address the test mail should be sent to")}</Text>
                                <Input
                                    className={"mt-3"}
                                    value={testReceiver}
                                    onChange={(e) => setTestReceiver(e.target.value)}
                                    required={true}
                                    type={"email"}
                                />
                            </Field>
                            <Button type={"submit"} color={"blue"}>
                                {t("Send test mail")}
                            </Button>
                        </FieldGroup>
                    </Fieldset>
                </form>
            </HeadingLayout>
        </>
    );
}

export const Route = createFileRoute("/_admin/a/settings/mail")({
    component: () => <MailSettings />,
});
