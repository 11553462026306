import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "../../../../../api/api";
import { toast } from "react-toastify";
import { FullCustomerContact, ProjectContactCategory } from "../../../../../api/generated";
import HeadingLayout from "../../../../../components/base/heading-layout";
import { Button } from "../../../../../components/base/button";
import ContactCard from "../../../../../components/contact-card";
import { Checkbox, CheckboxField, CheckboxGroup } from "../../../../../components/base/checkbox";
import { UsersIcon, PresentationChartBarIcon, ComputerDesktopIcon } from "@heroicons/react/24/outline";
import { ErrorMessage, Label } from "../../../../../components/base/fieldset";
import DialogCreateCustomerContact from "../../../../../components/dialogs/customer/dialog-create-customer-contact";

/**
 * The properties for {@link AssignProjectContactsForm}
 */
export type AssignProjectContactsFormProps = {};

/**
 * Type of the {@link AssignProjectContactsForm}'s `contactState` variable
 *
 * It stores a customer contact's association with the project in a set of booleans.
 */
type ContactState = {
    [contact: UUID]: {
        /** Is the contact to be included in any project related conversation? */
        cc: boolean;
        /** Is the contact responsible for management questions regarding the project? */
        management: boolean;
        /** Is the contact responsible for technical questions regarding the project? */
        technical: boolean;
    };
};

/**
 * A form to assign contacts to a project
 */
export default function AssignProjectContactsForm(props: AssignProjectContactsFormProps) {
    const [t] = useTranslation();
    const { projectId, taskId } = Route.useSearch();
    if (!projectId || !taskId) {
        return undefined;
    }

    const [contacts, setContacts] = React.useState<Array<FullCustomerContact>>([]);
    const [contactState, setContactState] = useState<ContactState>({});
    const [createContact, setCreateContact] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState<Array<string>>([]);

    /**
     * Submit the form
     */
    const submit = async () => {
        const res = await Api.customer.tasks.assignProjectContactsV0(taskId);

        res.match(
            (res) => {
                if (res.result === "Ok") {
                    history.back();
                } else {
                    if (res.error.missing_management) {
                        setErrorMsg((prev) => [
                            ...prev,
                            t("customer.assign-project-contacts.error.missing-management"),
                        ]);
                    } else if (res.error.missing_technical) {
                        setErrorMsg((prev) => [...prev, t("customer.assign-project-contacts.error.missing-technical")]);
                    }
                }
            },
            (err) => toast.error(err.message),
        );
    };

    /**
     * Refresh the contacts of the customer
     */
    const refreshContacts = () => {
        Api.customer.contacts.all().then((result) =>
            result.match(
                ({ list }) => setContacts(list),
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Refresh the contacts state regarding the project
     */
    const refreshContactState = () => {
        Api.customer.projects.contacts.get(projectId).then((result) =>
            result.match(
                ({ cc, technical, management }) => {
                    const newState: ContactState = {};
                    for (const [key, list] of [
                        ["cc", cc],
                        ["technical", technical],
                        ["management", management],
                    ] as const) {
                        for (const { uuid } of list) {
                            if (newState[uuid] === undefined)
                                newState[uuid] = {
                                    cc: false,
                                    technical: false,
                                    management: false,
                                };
                            newState[uuid][key] = true;
                        }
                    }
                    setContactState(newState);
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Set the state of a contact
     *
     * @param checked Whether to add or remove the contact
     * @param contact_uuid Contact UUID
     * @param category The category to add or remove the contact to/from
     */
    const updateContactState = (checked: boolean, contact_uuid: UUID, category: ProjectContactCategory) => {
        (checked
            ? Api.customer.projects.contacts.add(projectId, contact_uuid, category)
            : Api.customer.projects.contacts.remove(projectId, contact_uuid, category)
        ).then((result) =>
            result.match(
                () => refreshContactState(),
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => {
        refreshContacts();
        refreshContactState();
    }, [projectId, taskId]);

    return (
        <HeadingLayout
            heading={t("customer.assign-project-contacts.heading.assign-project-contacts")}
            headingDescription={t("customer.assign-project-contacts.description.assign-project-contacts")}
            headingChildren={<Button onClick={submit}>{t("button.submit")}</Button>}
        >
            {errorMsg.map((err) => (
                <ErrorMessage>{err}</ErrorMessage>
            ))}
            <div className={"flex flex-row-reverse  "}>
                <Button onClick={() => setCreateContact(true)}>
                    {t("customer.assign-project-contacts.button.create-contact")}
                </Button>
            </div>
            <div className={"grid gap-6 xl:grid-cols-2"}>
                {contacts.map((contact) => (
                    <ContactCard
                        key={contact.uuid}
                        contact={{ ...contact, mail: contact.email, invited: !!contact.invite }}
                        actions={
                            <div className={"dark:text-zinc-5 0 mr-6 flex flex-col gap-1"}>
                                <CheckboxGroup>
                                    <CheckboxField>
                                        <Checkbox
                                            checked={contactState[contact.uuid]?.technical}
                                            onChange={(checked) => {
                                                updateContactState(checked, contact.uuid, "Technical");
                                            }}
                                        />
                                        <span className={"flex items-center gap-2"}>
                                            <ComputerDesktopIcon className={"size-5 dark:text-zinc-500"} />
                                            <Label>{t("label.contact-it")}</Label>
                                        </span>
                                    </CheckboxField>
                                    <CheckboxField>
                                        <Checkbox
                                            checked={contactState[contact.uuid]?.management}
                                            onChange={(checked) => {
                                                updateContactState(checked, contact.uuid, "Management");
                                            }}
                                        />
                                        <span className={"flex items-center gap-2"}>
                                            <PresentationChartBarIcon className={"size-5 dark:text-zinc-500"} />
                                            <Label>{t("label.contact-management")}</Label>
                                        </span>
                                    </CheckboxField>
                                    <CheckboxField>
                                        <Checkbox
                                            checked={contactState[contact.uuid]?.cc}
                                            onChange={(checked) => {
                                                updateContactState(checked, contact.uuid, "Cc");
                                            }}
                                        />
                                        <span className={"flex items-center gap-2"}>
                                            <UsersIcon className={"size-5 dark:text-zinc-500"} />
                                            <Label>{t("label.contact-cc")}</Label>
                                        </span>
                                    </CheckboxField>
                                </CheckboxGroup>

                                {!contact.user && !contact.invite && (
                                    <Button
                                        className={"mt-3"}
                                        plain={true}
                                        onClick={() => {
                                            Api.customer.contacts.invite(contact.uuid).then((res) =>
                                                res.match(
                                                    () => refreshContacts(),
                                                    (err) => toast.error(err.message),
                                                ),
                                            );
                                        }}
                                    >
                                        {t("button.invite-to-platform")}
                                    </Button>
                                )}
                            </div>
                        }
                    />
                ))}
            </div>
            {createContact && (
                <DialogCreateCustomerContact
                    open={true}
                    onClose={() => setCreateContact(false)}
                    onSubmit={() => {
                        refreshContacts();
                        setCreateContact(false);
                    }}
                />
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_customer/c/forms/tasks/assign-project-contacts")({
    component: AssignProjectContactsForm,
    // eslint-disable-next-line
    validateSearch: (search: Record<string, UUID>): { projectId?: UUID; taskId?: UUID } => {
        return {
            projectId: "projectId" in search && search.projectId ? search.projectId : undefined,
            taskId: "taskId" in search && search.taskId ? search.taskId : undefined,
        };
    },
});
