import React from "react";
import { Button } from "../../base/button";
import HeadingLayout from "../../base/heading-layout";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Text } from "../../base/text";

/**
 * The properties for {@link LayoutEditQuestion}
 */
export type LayoutEditQuestionProps = {
    headingChildren: React.ReactNode;
    children?: React.ReactNode;
};

/**
 * The layout for editing questions
 */
export default function LayoutEditQuestion(props: LayoutEditQuestionProps) {
    const [t] = useTranslation();

    return (
        <>
            <Button plain={true} href={"/i/questionnaires/questions"}>
                <ChevronLeftIcon />
                <Text>{t("button.back-to-overview")}</Text>
            </Button>

            <HeadingLayout
                className={"mt-6"}
                heading={t("internal.edit-questionnaire-question.heading.edit-question")}
                headingChildren={props.headingChildren}
            >
                {props.children}
            </HeadingLayout>
        </>
    );
}
