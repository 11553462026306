/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptWithPwRequest,
  AcceptWithWARequest,
  ApiErrorResponse,
  CreateUserInviteRequest,
  FormResultForSimpleUserInviteAndCreateUserInviteErrors,
  FormResultForWebAuthnSchemaAndNever,
  GetUserInviteResponse,
  ListForSimpleUserInvite,
  WebAuthnRegisterResult,
} from '../models/index';

export interface AcceptWithPasswordRequest {
    uuid: string;
    AcceptWithPwRequest: AcceptWithPwRequest;
}

export interface AcceptWithWebauthnRequest {
    uuid: string;
    AcceptWithWARequest: AcceptWithWARequest;
}

export interface CompleteInvitesWebauthnRequest {
    body: any | null;
}

export interface CreateUserInviteOperationRequest {
    CreateUserInviteRequest: CreateUserInviteRequest;
}

export interface DeleteUserInviteRequest {
    uuid: string;
}

export interface GetUserInviteRequest {
    uuid: string;
}

/**
 * 
 */
export class UserInvitesApi extends runtime.BaseAPI {

    /**
     * Accepts an invitation by providing a password for authentication
     * Accepts an invitation by providing a password for authentication
     */
    async acceptWithPasswordRaw(requestParameters: AcceptWithPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling acceptWithPassword().'
            );
        }

        if (requestParameters['AcceptWithPwRequest'] == null) {
            throw new runtime.RequiredError(
                'AcceptWithPwRequest',
                'Required parameter "AcceptWithPwRequest" was null or undefined when calling acceptWithPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/accept/{uuid}/with-password`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['AcceptWithPwRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accepts an invitation by providing a password for authentication
     * Accepts an invitation by providing a password for authentication
     */
    async acceptWithPassword(requestParameters: AcceptWithPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptWithPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Accepts an invitation by providing a webauthn key for authentication
     * Accepts an invitation by providing a webauthn key for authentication
     */
    async acceptWithWebauthnRaw(requestParameters: AcceptWithWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndNever>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling acceptWithWebauthn().'
            );
        }

        if (requestParameters['AcceptWithWARequest'] == null) {
            throw new runtime.RequiredError(
                'AcceptWithWARequest',
                'Required parameter "AcceptWithWARequest" was null or undefined when calling acceptWithWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/accept/{uuid}/with-webauthn`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['AcceptWithWARequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Accepts an invitation by providing a webauthn key for authentication
     * Accepts an invitation by providing a webauthn key for authentication
     */
    async acceptWithWebauthn(requestParameters: AcceptWithWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndNever> {
        const response = await this.acceptWithWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete the webauthn challenge for accepting the invite by registering a key
     * Complete the webauthn challenge for accepting the invite by registering a key
     */
    async completeInvitesWebauthnRaw(requestParameters: CompleteInvitesWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebAuthnRegisterResult>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling completeInvitesWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/complete-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Complete the webauthn challenge for accepting the invite by registering a key
     * Complete the webauthn challenge for accepting the invite by registering a key
     */
    async completeInvitesWebauthn(requestParameters: CompleteInvitesWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebAuthnRegisterResult> {
        const response = await this.completeInvitesWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite a new (local) user
     * Invite a new (local) user
     */
    async createUserInviteRaw(requestParameters: CreateUserInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSimpleUserInviteAndCreateUserInviteErrors>> {
        if (requestParameters['CreateUserInviteRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateUserInviteRequest',
                'Required parameter "CreateUserInviteRequest" was null or undefined when calling createUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateUserInviteRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Invite a new (local) user
     * Invite a new (local) user
     */
    async createUserInvite(requestParameters: CreateUserInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSimpleUserInviteAndCreateUserInviteErrors> {
        const response = await this.createUserInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an outstanding invite
     * Delete an outstanding invite
     */
    async deleteUserInviteRaw(requestParameters: DeleteUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-invites/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an outstanding invite
     * Delete an outstanding invite
     */
    async deleteUserInvite(requestParameters: DeleteUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve all outstanding invites (expired or not)
     * Retrieve all outstanding invites (expired or not)
     */
    async getAllUserInvitesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForSimpleUserInvite>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve all outstanding invites (expired or not)
     * Retrieve all outstanding invites (expired or not)
     */
    async getAllUserInvites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForSimpleUserInvite> {
        const response = await this.getAllUserInvitesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets an invitation\'s details to display to the user before accepting
     * Gets an invitation\'s details to display to the user before accepting
     */
    async getUserInviteRaw(requestParameters: GetUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserInviteResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getUserInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-invites/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Gets an invitation\'s details to display to the user before accepting
     * Gets an invitation\'s details to display to the user before accepting
     */
    async getUserInvite(requestParameters: GetUserInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserInviteResponse> {
        const response = await this.getUserInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
