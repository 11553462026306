import React, { useEffect } from "react";
import { Field, Label, FieldGroup, Fieldset, Legend } from "./base/fieldset";
import { Input } from "./base/input";
import { Divider } from "./base/divider";
import { Button } from "./base/button";
import Logo from "../assets/logo_text.svg?react";
import { Api } from "../api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LoginPasswordResponse, SupportedLoginFlows } from "../api/generated";
import Form from "./base/form";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowLeftIcon, EyeIcon, EyeSlashIcon, FingerPrintIcon } from "@heroicons/react/20/solid";
import { Text } from "./base/text";
import { Subheading } from "./base/heading";
import { authenticate } from "../api/auth";

/**
 * The steps of the login form
 */
type LoginStep = "mail" | "password" | "mfa-totp" | "mfa-key" | "password-less" | "oidc";

/**
 * The properties for {@link Login}
 */
export type LoginProps = {
    /** The function that should be executed on a successful login */
    onLogin: () => void;
};

/**
 * The login component
 */
export default function Login(props: LoginProps) {
    const [t] = useTranslation();

    const [flows, setFlows] = React.useState<SupportedLoginFlows>();

    const [step, setStep] = React.useState<LoginStep>("mail");

    const [mail, setMail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    const [mfaFlows, setMfaFlows] = React.useState<LoginPasswordResponse>();

    const [totp, setTotp] = React.useState("");

    /**
     * Retrieve the possible login flows
     *
     * @param mail The mail that should be used to retrieve the available login flows
     */
    const getLoginFlows = (mail: string) => {
        Api.auth.getLoginFlows(mail).then((res) =>
            res.match(
                ({ optional }) => {
                    if (!optional) {
                        toast.error("No login flow");
                        return;
                    }

                    setFlows(optional);
                    if (optional.key) {
                        setStep("password-less");
                    } else if (optional.password) {
                        setStep("password");
                    } else if (optional.oidc) {
                        setStep("oidc");
                    }
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Perform a login using the password login flow
     */
    const performPasswordLogin = () => {
        Api.auth.performPasswordLogin(mail, password).then((res) =>
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        if (res.value.res === "Finished") {
                            props.onLogin();
                        } else {
                            if (res.value.mfa.has_webauthn) {
                                setStep("mfa-key");
                            } else if (res.value.mfa.has_totp) {
                                setStep("mfa-totp");
                            }

                            setMfaFlows(res.value);
                        }
                    } else {
                        if (res.error.password) {
                            toast.error(t("login.toast.invalid-credentials"));
                        }
                    }
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Perform a login using the webauthn flow
     */
    const performWebauthnLogin = () => {
        authenticate("passwordless", { mail }).then((res) =>
            res.match(
                () => {
                    props.onLogin();
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Reset the current state
     */
    const reset = () => {
        setFlows(undefined);
        setStep("mail");
        setMail("");
    };

    useEffect(() => {
        const mail = window.localStorage.getItem("mail");
        if (mail !== null) {
            setMail(mail);
            getLoginFlows(mail);
        }
    }, []);

    return (
        <div className={"flex h-screen w-full items-center justify-center bg-zinc-50 p-3 dark:bg-neutral-950"}>
            <div className="w-full max-w-md rounded-xl border bg-white dark:border-zinc-800 dark:bg-zinc-900 dark:before:pointer-events-none forced-colors:outline">
                <div
                    className={
                        "grid h-full w-full place-items-start justify-items-center overflow-hidden p-6 py-8 sm:p-8 lg:p-12"
                    }
                >
                    <Logo className="dark:fill-white" />
                    <div className={"mt-12 flex w-full max-w-sm space-y-8 overflow-hidden p-1"}>
                        <AnimatePresence initial={false} mode={"wait"}>
                            {step === "mail" && (
                                <motion.div
                                    className={"w-full"}
                                    key={"mail"}
                                    initial={{ opacity: 0, x: 300 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -300 }}
                                >
                                    <Form onSubmit={() => getLoginFlows(mail)}>
                                        <Fieldset>
                                            <FieldGroup>
                                                <Field>
                                                    <Label>{t("label.mail")}</Label>
                                                    <Input
                                                        required={true}
                                                        autoComplete={"email"}
                                                        type={"email"}
                                                        value={mail}
                                                        onChange={(e) => setMail(e.target.value)}
                                                    />
                                                </Field>

                                                <Button type={"submit"} className={"w-full"}>
                                                    {t("button.next")}
                                                </Button>

                                                <Divider />

                                                <Button
                                                    className={"w-full"}
                                                    href={"/api/frontend/v1/common/oidc/oidc-login"}
                                                    external={true}
                                                    color={"blue"}
                                                >
                                                    {t("login.button.sso-sign-in")}
                                                </Button>
                                            </FieldGroup>
                                        </Fieldset>
                                    </Form>
                                </motion.div>
                            )}

                            {step === "oidc" && (
                                <motion.div
                                    key={"oidc"}
                                    className={"w-full"}
                                    initial={{ opacity: 0, x: 300 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    // This exits to the right as the only way the form continues is by returning
                                    // to the mail input mask
                                    exit={{ opacity: 0, x: 300 }}
                                >
                                    <Button
                                        className={"w-full"}
                                        external={true}
                                        href={"/api/frontend/v1/common/oidc/oidc-login"}
                                        color={"blue"}
                                    >
                                        {t("login.button.sso-sign-in")}
                                    </Button>

                                    <Button
                                        plain={true}
                                        className={"mt-6 text-sm font-medium text-white dark:text-zinc-300"}
                                        onClick={() => reset()}
                                    >
                                        <ArrowLeftIcon />
                                        {t("login.button.not-you-change-account")}
                                    </Button>
                                </motion.div>
                            )}

                            {step === "password-less" && flows?.key && (
                                <motion.div
                                    key={"password-less"}
                                    className={"w-full"}
                                    initial={{ opacity: 0, x: 300 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -300 }}
                                >
                                    <Text>{t("login.label.login-as", { mail })}</Text>

                                    <Button
                                        plain={true}
                                        className={"!text-xs font-medium text-white dark:text-zinc-300"}
                                        onClick={() => reset()}
                                    >
                                        <ArrowLeftIcon />
                                        {t("login.button.not-you-change-account")}
                                    </Button>

                                    <Button
                                        color={"blue"}
                                        className={"mt-3 w-full"}
                                        onClick={() => performWebauthnLogin()}
                                    >
                                        <FingerPrintIcon />
                                        {t("login.button.login-security-key")}
                                    </Button>

                                    {flows.password && (
                                        <Button plain={true} onClick={() => setStep("password")}>
                                            {t("login.button.use-password")}
                                        </Button>
                                    )}
                                </motion.div>
                            )}

                            {step === "password" && flows?.password && (
                                <motion.div
                                    key={"password"}
                                    className={"w-full"}
                                    initial={{ opacity: 0, x: 300 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -300 }}
                                >
                                    <Subheading>{t("login.label.login-as", { mail })}</Subheading>

                                    <Button
                                        plain={true}
                                        className={"!text-xs font-medium text-white dark:text-zinc-300"}
                                        onClick={() => reset()}
                                    >
                                        <ArrowLeftIcon />
                                        {t("login.button.not-you-change-account")}
                                    </Button>

                                    <Form onSubmit={performPasswordLogin}>
                                        {/* This input field helps the fill in features of the browser */}
                                        <Input
                                            type={"email"}
                                            className={"hidden"}
                                            value={mail}
                                            readOnly={true}
                                            autoComplete={"email"}
                                        />
                                        <Field className={"mt-6"}>
                                            <Label>{t("label.password")}</Label>
                                            <div className={"mt-3 flex gap-3"}>
                                                <Input
                                                    autoFocus={true}
                                                    type={showPassword ? "text" : "password"}
                                                    autoComplete={"current-password"}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <Button plain={true} onClick={() => setShowPassword((prev) => !prev)}>
                                                    {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                                                </Button>
                                            </div>
                                        </Field>

                                        <Button
                                            type={"submit"}
                                            color={"blue"}
                                            className={"mt-5 w-full"}
                                            onClick={() => setPassword(password)}
                                        >
                                            {t("button.next")}
                                        </Button>
                                    </Form>

                                    {flows.key && (
                                        <Button
                                            className={"mt-6"}
                                            plain={true}
                                            onClick={() => setStep("password-less")}
                                        >
                                            {t("login.button.use-security-key")}
                                        </Button>
                                    )}
                                </motion.div>
                            )}

                            {step === "mfa-totp" && (
                                <motion.div
                                    key={"mfa-totp"}
                                    className={"w-full"}
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: -300, opacity: 0 }}
                                >
                                    <Form
                                        onSubmit={() => {
                                            Api.auth.verifyTOTP(totp).then((res) =>
                                                res.match(
                                                    () => {
                                                        props.onLogin();
                                                    },
                                                    (err) => toast.error(err.message),
                                                ),
                                            );
                                        }}
                                    >
                                        <Fieldset className={"flex flex-col gap-6"}>
                                            <FieldGroup>
                                                <Legend>{t("login.heading.mfa-needed")}</Legend>
                                                <Field>
                                                    <Label>{t("label.totp-token")}</Label>
                                                    <Input
                                                        required={true}
                                                        type={"number"}
                                                        autoComplete={"one-time-code"}
                                                        value={totp}
                                                        onChange={(e) => setTotp(e.target.value)}
                                                    />
                                                </Field>

                                                <Button type={"submit"} color={"blue"} className={"w-full"}>
                                                    {t("button.sign-in")}
                                                </Button>
                                            </FieldGroup>

                                            {mfaFlows?.res === "NeedMFA" && mfaFlows.mfa.has_webauthn && (
                                                <div className={"flex justify-start"}>
                                                    <Button plain={true} onClick={() => setStep("mfa-key")}>
                                                        {t("login.button.use-mfa-security-key")}
                                                    </Button>
                                                </div>
                                            )}
                                        </Fieldset>
                                    </Form>
                                </motion.div>
                            )}

                            {step === "mfa-key" && (
                                <motion.div
                                    key={"mfa-key"}
                                    className={"w-full"}
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: -300, opacity: 0 }}
                                >
                                    <Form
                                        onSubmit={() => {
                                            authenticate("mfa", { undefined }).then((res) =>
                                                res.match(
                                                    () => {
                                                        props.onLogin();
                                                    },
                                                    (err) => toast.error(err.message),
                                                ),
                                            );
                                        }}
                                    >
                                        <Fieldset>
                                            <Legend>{t("login.heading.mfa-needed")}</Legend>

                                            <Button type={"submit"} color={"blue"} className={"mt-3 w-full"}>
                                                <FingerPrintIcon />
                                                {t("login.button.login-security-key")}
                                            </Button>

                                            {mfaFlows?.res === "NeedMFA" && mfaFlows.mfa.has_totp && (
                                                <div className={"mt-6 flex justify-start"}>
                                                    <Button plain={true} onClick={() => setStep("mfa-totp")}>
                                                        {t("login.button.use-mfa-totp")}
                                                    </Button>
                                                </div>
                                            )}
                                        </Fieldset>
                                    </Form>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    );
}
