import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Textarea } from "src/components/base/textarea";
import { Input } from "src/components/base/input";
import { Checkbox, CheckboxGroup } from "src/components/base/checkbox";
import { EnterPentestKickoffResultsV0Request, EnterPentestKickoffResultsV1Request } from "src/api/generated";
import { Listbox, ListboxOption } from "src/components/base/listbox";

/**
 * The properties for {@link EnterKickoffResults}
 */
export type EnterKickoffResultsProps = {};

/**
 *  Enter the results of a kickoff
 */
export default function EnterKickoffResults(props: EnterKickoffResultsProps) {
    const [t] = useTranslation();

    const { taskId } = Route.useParams();

    const enterForm = useForm({
        defaultValues: {
            scanDate: "",
            antiScan: false,
            reportDeadline: "",
            reportDelivery: "Share" as EnterPentestKickoffResultsV1Request["report_delivery"],
            dialogDate: "",
            skipDialog: false,
            notes: "",
        },
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setKickoffResultsV1(taskId, {
                dialog_date: value.skipDialog
                    ? { state: "Canceled" }
                    : value.dialogDate === ""
                      ? undefined
                      : {
                            date: new Date(value.dialogDate).toISOString(),
                            state: "Scheduled",
                        },
                scan_date: new Date(value.scanDate).toISOString(),
                report_deadline: value.reportDeadline === "" ? undefined : new Date(value.reportDeadline).toISOString(),
                report_delivery: value.reportDelivery,
                anti_scan: value.antiScan,
                notes: value.notes,
            });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        history.back();
                    } else {
                        if (res.error.dialog_date) {
                            formApi.setFieldMeta("dialogDate", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                        if (res.error.report_deadline) {
                            formApi.setFieldMeta("reportDeadline", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                        if (res.error.scan_date) {
                            formApi.setFieldMeta("scanDate", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                        if (res.error.report_delivery) {
                            formApi.setFieldMeta("notes", (meta) => {
                                meta.errors.push(
                                    t("internal.enter-kickoff-results.error.report-delivery-without-notes"),
                                );
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const skipDialog = enterForm.useStore((store) => store.values.skipDialog);

    return (
        <HeadingLayout heading={t("internal.enter-kickoff-results.heading")}>
            <Form onSubmit={enterForm.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <enterForm.Field name={"scanDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.scan-date")}</RequiredLabel>
                                    <Input
                                        autoFocus={true}
                                        required={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                    />
                                    {fieldApi.state.meta.errors.map((e) => (
                                        <ErrorMessage>{e}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </enterForm.Field>

                        <enterForm.Field name={"antiScan"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.anti-scan-non-deactivatable")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </enterForm.Field>

                        <enterForm.Field name={"reportDeadline"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.report-date")}</Label>
                                    <Input
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                    />
                                    {fieldApi.state.meta.errors.map((e) => (
                                        <ErrorMessage>{e}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </enterForm.Field>

                        <enterForm.Field name={"reportDelivery"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.report-delivery-method")}</RequiredLabel>
                                    <Listbox
                                        value={fieldApi.state.value}
                                        onChange={(e: EnterPentestKickoffResultsV0Request["report_delivery"]) =>
                                            fieldApi.handleChange(e)
                                        }
                                    >
                                        <ListboxOption value={"Share"}>
                                            <Label>{t("label.delivery-share")}</Label>
                                        </ListboxOption>
                                        <ListboxOption value={"Postal"}>
                                            <Label>{t("label.delivery-postal")}</Label>
                                        </ListboxOption>
                                        <ListboxOption value={"Other"}>
                                            <Label>{t("label.delivery-other")}</Label>
                                        </ListboxOption>
                                    </Listbox>
                                </Field>
                            )}
                        </enterForm.Field>

                        <enterForm.Field name={"dialogDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.dialog-date")}</Label>
                                    <Input
                                        disabled={skipDialog}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                    />
                                    {fieldApi.state.meta.errors.map((e) => (
                                        <ErrorMessage>{e}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </enterForm.Field>

                        <enterForm.Field name={"skipDialog"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.skip-dialog")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </enterForm.Field>

                        <enterForm.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </enterForm.Field>

                        <Button type={"submit"}>{t("button.submit")}</Button>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v1")({
    component: EnterKickoffResults,
});
