import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { sortDates } from "src/utils/sorter";
import { useTranslation } from "react-i18next";
import INTERNAL_TASK_CONTEXT from "src/context/tasks-internal";
import { displayDeadline } from "src/utils/tasks";
import { Button } from "src/components/base/button";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/16/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

/**
 * The properties for {@link MyTasks}
 */
export type MyTasksProps = {};

/**
 * My Tasks
 */
export default function MyTasks(props: MyTasksProps) {
    const [t] = useTranslation();
    const { tasks } = React.useContext(INTERNAL_TASK_CONTEXT);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader>{t("label.due-until")}</TableHeader>
                    <TableHeader className={"w-0"}>
                        <span className={"sr-only"}>{t("accessibility.actions")}</span>
                    </TableHeader>
                    <TableHeader>{t("label.task")}</TableHeader>
                    <TableHeader>{t("label.project")}</TableHeader>
                    <TableHeader>{t("label.legal-business-name")}</TableHeader>
                </TableRow>
            </TableHead>
            <TableBody>
                {tasks.my_tasks
                    .sort((a, b) =>
                        sortDates(
                            a.deadline ? new Date(a.deadline) : undefined,
                            b.deadline ? new Date(b.deadline) : undefined,
                        ),
                    )
                    .map((task) =>
                        // V0
                        task.data.kind === "ScheduleKickOffV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/schedule-kickoff-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell> {t("tasks.label.schedule-kickoff")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterKickOffResultsV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-kickoff-results-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-kickoff-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "UploadPentestKickoffPresentationV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/upload-kickoff-presentation"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.upload-kickoff-presentation")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "UploadPentestDialogPresentationV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/upload-dialog-presentation"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.upload-dialog-presentation")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterDialogResultsV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-dialog-results-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-dialog-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "UploadPentestMgmtPresentationPresentationV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/upload-mgmt-presentation"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.upload-mgmt-presentation")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterMgmtPresentationResultsV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-mgmt-results-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-mgmt-presentation-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "UploadPentestReportV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/upload-pentest-report"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.upload-pentest-report")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "ExternalV0" ? (
                            <TableRow key={task.uuid}>
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    <Button
                                        onClick={async () => {
                                            const res = await Api.internal.tasks.finishExternalV0(task.uuid);
                                            if (res.isErr) {
                                                toast.error(res.err.message);
                                            }
                                        }}
                                    >
                                        <CheckCircleIcon />
                                    </Button>
                                </TableCell>
                                {task.data.description === "InviteITDialog" ? (
                                    <TableCell>{t("tasks.label.invite-it-dialog")}</TableCell>
                                ) : task.data.description === "InviteCustomerUser" ? (
                                    <TableCell>{t('tasks.label.invite-customer-user"')}</TableCell>
                                ) : task.data.description === "InviteMgmtPresentation" ? (
                                    <TableCell>{t("tasks.label.invite-mgmt-presentation")}</TableCell>
                                ) : task.data.description === "PentestExternalImportData" ? (
                                    <TableCell>{t("tasks.label.import-data")}</TableCell>
                                ) : task.data.description === "PentestExternalStartInitalScan" ? (
                                    <TableCell>{t("tasks.label.start-initial-scan")}</TableCell>
                                ) : task.data.description === "PentestExternalScanShodan" ? (
                                    <TableCell>{t("tasks.label.scan-shodan")}</TableCell>
                                ) : task.data.description === "PentestExternalCreateServices" ? (
                                    <TableCell>{t("tasks.label.create-services")}</TableCell>
                                ) : task.data.description === "PentestExternalTestSsl" ? (
                                    <TableCell>{t("tasks.label.test-ssl")}</TableCell>
                                ) : task.data.description === "PentestExternalTestCommon" ? (
                                    <TableCell>{t("tasks.label.test-common")}</TableCell>
                                ) : task.data.description === "PentestExternalRunKrakenAssi" ? (
                                    <TableCell>{t("tasks.label.run-kraken-assi")}</TableCell>
                                ) : task.data.description === "PentestExternalAddHttpFindings" ? (
                                    <TableCell>{t("tasks.label.add-http-findings")}</TableCell>
                                ) : task.data.description === "InviteITDebrief" ? (
                                    <TableCell>{t("tasks.label.invite-it-debrief")}</TableCell>
                                ) : (
                                    <TableCell>{task.data.description}</TableCell>
                                )}
                                <TableCell>{task.project?.kind}</TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : // V1
                        task.data.kind === "ScheduleKickOffV1" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/schedule-kickoff-v1"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.schedule-kickoff")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterKickOffResultsV1" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-kickoff-results-v1"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-kickoff-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "ScheduleDebriefV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/schedule-debrief-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.schedule-debrief")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterDialogResultsV1" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-dialog-results-v1"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-dialog-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterDebriefResultsV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-debrief-results-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-debrief-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "ScheduleDialogV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/schedule-dialog-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell>{task.deadline && displayDeadline(new Date(task.deadline))}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.schedule-dialog")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "EnterMgmtPresentationResultsV1" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/enter-mgmt-results-v1"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell>{task.deadline && displayDeadline(new Date(task.deadline))}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.enter-mgmt-presentation-results")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : task.data.kind === "ScheduleMgmtPresentationV0" ? (
                            <TableRow
                                key={task.uuid}
                                href={"/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0"}
                                params={{ taskId: task.uuid }}
                            >
                                <TableCell>{task.deadline && displayDeadline(new Date(task.deadline))}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{t("tasks.label.schedule-mgmt-presentation")}</TableCell>
                                <TableCell className={"flex items-center gap-3"}>
                                    {task.project?.kind}
                                    <Button
                                        plain={true}
                                        href={"/i/pentests/$projectId/general"}
                                        params={{ projectId: task.data.project }}
                                    >
                                        <ArrowTopRightOnSquareIcon />
                                    </Button>
                                </TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ) : (
                            // Unknown
                            <TableRow key={task.uuid}>
                                <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                    {task.deadline && displayDeadline(new Date(task.deadline))}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{JSON.stringify(task.data)}</TableCell>
                                <TableCell>{task.project?.kind}</TableCell>
                                <TableCell>{task.project?.customer.legal_name}</TableCell>
                            </TableRow>
                        ),
                    )}
            </TableBody>
        </Table>
    );
}

export const Route = createFileRoute("/_internal/i/_tasks/tasks/my")({
    component: MyTasks,
});
