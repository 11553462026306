import React from "react";
import { EditQuestionProps } from "./edit-question";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "../../../api/api";
import { toast } from "react-toastify";
import LayoutEditQuestion from "./layout-edit-question";
import { Button } from "../../base/button";
import Question from "../../questionnaire/question";
import { Description, Field, FieldGroup, Fieldset, Label } from "../../base/fieldset";
import { Divider } from "../../base/divider";
import { Input } from "../../base/input";
import { Textarea } from "../../base/textarea";
import BlockUnsavedChanges from "../../base/unsaved-changes";

/**
 * The properties for {@link EditQuestionBoolean}
 */
export type EditQuestionBooleanProps = EditQuestionProps<"Boolean">;

/**
 * Edit view for questions of type boolean
 */
export default function EditQuestionBoolean(props: EditQuestionBooleanProps) {
    const [t] = useTranslation();
    const { question } = props;

    const editForm = useForm({
        defaultValues: {
            name: question.name,
            notes: question.notes,
            title: question.title,
            description: question.description,
        },
        onSubmit: async ({ value, formApi }) => {
            const res = await Api.internal.questionnaires.questions.update(question.uuid, {
                name: value.name,
                notes: value.notes,
                title: value.title,
                description: value.description,
            });

            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        toast.success(t("toast.saved-changes"));
                        props.reloadQuestion();
                        formApi.reset();
                    } else {
                        if (res.error.name) {
                            formApi.setFieldMeta("name", (meta) => {
                                meta.errors.push(t("error.name-in-use"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = editForm.useStore((state) => state.isDirty);

    return (
        <LayoutEditQuestion
            headingChildren={
                <div className={"flex gap-6"}>
                    <Button plain={true} disabled={!isDirty} onClick={editForm.reset}>
                        {t("button.reset")}
                    </Button>
                    <Button color={"blue"} disabled={!isDirty} onClick={editForm.handleSubmit}>
                        {t("button.save")}
                    </Button>
                </div>
            }
        >
            <editForm.Subscribe>
                {({ values }) => (
                    <Question
                        disabled={true}
                        question={{
                            uuid: question.uuid,
                            name: values.name,
                            notes: values.notes,
                            title: values.title,
                            description: values.description,
                            created: question.created,
                            data: { kind: "Boolean" },
                        }}
                    />
                )}
            </editForm.Subscribe>

            <Fieldset>
                <FieldGroup className={"row-span-2"}>
                    <Divider />

                    <editForm.Field name={"name"}>
                        {(fieldApi) => (
                            <Field className={"grid grid-cols-2"}>
                                <span>
                                    <Label>{t("label.internal-name")}</Label>
                                    <Description>{t("description.internal-name")}</Description>
                                </span>
                                <Input
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>
                    <editForm.Field name={"notes"}>
                        {(fieldApi) => (
                            <Field className={"grid grid-cols-2"}>
                                <span>
                                    <Label>{t("label.internal-notes")}</Label>
                                    <Description>{t("description.internal-notes")}</Description>
                                </span>
                                <Textarea
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>

                    <Divider soft={true} />

                    <editForm.Field name={"title"}>
                        {(fieldApi) => (
                            <Field className={"grid grid-cols-2"}>
                                <Label>{t("label.questionnaire-title")}</Label>
                                <Input
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>
                    <editForm.Field name={"description"}>
                        {(fieldApi) => (
                            <Field className={"grid grid-cols-2"}>
                                <Label>{t("label.questionnaire-description")}</Label>
                                <Textarea
                                    className={"place-self-start"}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </editForm.Field>
                </FieldGroup>
            </Fieldset>

            <BlockUnsavedChanges condition={isDirty} />
        </LayoutEditQuestion>
    );
}
