import { Api } from "src/api/api";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { validateDate } from "src/utils/validators";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Button } from "src/components/base/button";
import React from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { FullPentestProject } from "src/api/generated";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

/**
 * The properties for {@link ChangeDate}
 */
export type DialogChangeDateProps = {
    /** Type of the appointment*/
    type: AppointmentType;
};

/** Type of the appointment*/
type AppointmentType = "kickoff" | "dialog" | "mgmt";

/**
 * Change Date of an appointment
 */
export default function ChangeDate(props: DialogChangeDateProps) {
    const { type } = props;

    const [t] = useTranslation();

    const { obj, reset } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as FullPentestProject;

    const changeForm = useForm({
        defaultValues: {
            date: "",
        },
        onSubmit: async ({ value, formApi }) => {
            const d = validateDate(value.date);
            if (d.isErr) {
                formApi.setFieldMeta("date", (meta) => {
                    meta.errors.push(t("error.invalid-date"));
                    return meta;
                });
                return;
            }
            let res;
            switch (type) {
                case "kickoff": {
                    res = await Api.internal.projects.pentest.scheduleKickoffV0(project.uuid, {
                        kickoff_date: d.ok.toISOString(),
                    });
                    break;
                }
                case "dialog": {
                    res = await Api.internal.projects.pentest.scheduleDialogV0(project.uuid, {
                        dialog_date: { date: d.ok.toISOString(), state: "Scheduled" },
                    });
                    break;
                }
                case "mgmt": {
                    res = await Api.internal.projects.pentest.scheduleMgmtPresentationV0(project.uuid, {
                        presentation_date: { date: d.ok.toISOString(), state: "Scheduled" },
                    });
                    break;
                }
                default:
                    break;
            }
            if (res !== undefined) {
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            reset();
                            history.back();
                        } else {
                            formApi.setFieldMeta("date", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                    },
                    (err) => toast.error(err.message),
                );
            }
        },
    });

    return (
        <Form onSubmit={changeForm.handleSubmit} className={"max-w-lg"}>
            <Fieldset>
                <div className={"flex items-center gap-3"}>
                    <Button plain={true} onClick={() => window.history.back()} params={{ projectId: obj.uuid }}>
                        <ChevronLeftIcon />
                    </Button>
                    <Legend>
                        {t("internal.projects.heading.change-date", {
                            name:
                                type === "kickoff"
                                    ? t("label.kickoff")
                                    : type === "dialog"
                                      ? t("label.dialog")
                                      : t("label.mgmt"),
                        })}
                    </Legend>
                </div>
                <FieldGroup>
                    <changeForm.Field name={"date"}>
                        {(fieldApi) => (
                            <Field>
                                <Label>{t("label.new-date")}</Label>
                                {project.kick_off && (
                                    <Description>
                                        {t("description.current-date", {
                                            date: new Date(project.kick_off).toLocaleString(),
                                        })}
                                    </Description>
                                )}
                                <Input
                                    type={"datetime-local"}
                                    autoFocus={true}
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((e) => (
                                    <ErrorMessage>{e}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </changeForm.Field>
                    <Button type={"submit"} color={"blue"}>
                        {t("button.change")}
                    </Button>
                </FieldGroup>
            </Fieldset>
        </Form>
    );
}
