import React, { useState } from "react";
import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "../base/dialog";
import { Field, FieldGroup, Fieldset, Label } from "../base/fieldset";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import { Code, Text } from "../base/text";
import { Button } from "../base/button";
import { Divider } from "../base/divider";
import { Input } from "../base/input";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "../base/form";
import DialogSudoMode from "src/components/dialogs/dialog-sudo-mode";
import WS from "src/api/ws";

/**
 * The properties for {@link DialogAddTotp}
 */
export type DialogAddTotpProps = DialogProps & {
    /** The callback function that should be executed if submitting the new authenticator was successful */
    onSubmit: () => void;
    /** The label of the totp key */
    label: string;
    /** The mail address of the user to generate the token for */
    mail: string;
    /** Which API should be used */
    endpoint: "reset" | "profile";
};

/**
 * Dialog to add a totp
 */
export default function DialogAddTotp(props: DialogAddTotpProps) {
    const [t] = useTranslation();

    const [secret] = React.useState(() => genSecret());
    const [token, setToken] = React.useState("");
    const [tokenError, setTokenError] = React.useState("");

    const [sudoMode, setSudoMode] = useState(false);

    // TODO: delete me
    WS.addEventListener("message.ReloadTasks", (event) => {});

    const url = genTotpUrl(props.mail, secret);

    return (
        <Dialog {...props}>
            <DialogTitle>{t("dialog.add-totp.heading")}</DialogTitle>
            <DialogBody className={"flex flex-col gap-6"}>
                <Text>{t("dialog.add-totp.description.qr")}</Text>

                <div className={"flex w-full justify-center"}>
                    <a href={url}>
                        <QRCodeSVG className={"size-60 rounded-lg bg-white p-2"} value={url} />
                    </a>
                </div>
                <span className={"text-center"}>
                    <Text>{t("dialog.add-totp.description.secret")}</Text>
                    <Code>{secret}</Code>
                </span>
                <Form
                    onSubmit={() => {
                        let res;

                        if (props.endpoint === "reset") {
                            res = Api.common.resetCredentials.addTotp(secret, token, props.label);
                        } else {
                            res = Api.common.user.totp.add({ secret, token, label: props.label });
                        }

                        res.then((res) =>
                            res.match(
                                (res) => {
                                    if (res.result === "Ok") {
                                        props.onSubmit();
                                    } else if (res.error.token) {
                                        setTokenError(t("dialog.add-totp.error.invalid-token"));
                                    } else if (res.error.missing_sudo) {
                                        setSudoMode(true);
                                    }
                                },
                                (err) => toast.error(err.message),
                            ),
                        );
                    }}
                >
                    <Fieldset>
                        <Divider />
                        <FieldGroup>
                            <Field>
                                <Label>{t("label.totp-token")}</Label>
                                <Text>{t("description.totp-token")}</Text>
                                <Input
                                    className={"mt-1"}
                                    autoComplete={"one-time-code"}
                                    required={true}
                                    autoFocus={true}
                                    minLength={6}
                                    maxLength={8}
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                />

                                {tokenError !== "" && (
                                    <Text className={"col-span-2 mt-2 !text-red-500"}>{tokenError}</Text>
                                )}
                            </Field>
                            <Divider />
                            <DialogActions>
                                <Button type={"submit"} color={"blue"}>
                                    {t("dialog.add-totp.button.add-totp")}
                                </Button>
                            </DialogActions>
                        </FieldGroup>
                    </Fieldset>
                </Form>
            </DialogBody>

            {sudoMode && (
                <DialogSudoMode
                    open={true}
                    onSubmit={() => {
                        setSudoMode(false);
                    }}
                    onClose={() => {
                        setSudoMode(false);
                    }}
                />
            )}
        </Dialog>
    );
}

/**
 * Generated a secret and base32 encode it
 *
 * @returns The secret as base32
 */
function genSecret() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
    const array = new Uint8Array(24);
    self.crypto.getRandomValues(array);
    let ret = "";
    for (let i = 0; i < array.length; i++) ret += alphabet[array[i] >> 4] + alphabet[array[i] & 0xf];
    return ret;
}

/**
 * Helper method to format the url
 *
 * @param label The user supplied label
 * @param secret The secret
 *
 * @returns The formatted string
 */
function genTotpUrl(label: string, secret: string) {
    return `otpauth://totp/${encodeURIComponent(label)}?secret=${encodeURIComponent(secret)}&issuer=${encodeURIComponent("Trufflepig Forensics")}`;
}
