import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Api, UUID } from "../api/api";
import { FullCustomer } from "../api/generated/";
import { Text } from "../components/base/text";

/** Data provided by the {@link CUSTOMER_CONTEXT} */
export type CustomerContext = {
    /** The customer */
    customer: FullCustomer;

    /** Reload the user's information */
    reset: () => void;
};

/** {@link React.Context} to access {@link FullCustomer}  */
const CUSTOMER_CONTEXT = React.createContext<CustomerContext>({
    /**
     * The customer
     */
    customer: {
        addresses: [],
        approved_at: "",
        contacts: [],
        created_at: "",
        legal_name: "",
        notes: "",
        project_manager: {
            display_name: "",
            has_profile_image: false,
            mail: "",
            phone: "",
            role: "Internal",
            uuid: "",
        },
        projects: [],
        uuid: "",
    },

    /**
     * Reset the user's information
     */
    reset: () => {},
});
CUSTOMER_CONTEXT.displayName = "CustomerContext";
export default CUSTOMER_CONTEXT;

/**
 * The properties for {@link CustomerProvider}
 */
export type CustomerProviderProps = {
    /** The id of the customer to query */
    customerId: UUID;
    /** Children of the context */
    children?: React.ReactNode;
};

/**
 * The provider for the customer
 */
export function CustomerProvider(props: CustomerProviderProps) {
    const { customerId, children } = props;

    const [customer, setCustomer] = React.useState<FullCustomer | null>(null);

    /**
     * Retrieve a customer
     */
    const fetchCustomer = async () => {
        const res = await Api.internal.customers.get(customerId);

        res.match(
            (opt) => setCustomer(opt.optional ? opt.optional : null),
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        fetchCustomer().then();
    }, [customerId]);

    if (!customer) {
        return <Text>Loading</Text>;
    }

    return (
        <CUSTOMER_CONTEXT.Provider value={{ customer, reset: () => fetchCustomer().then() }}>
            {children}
        </CUSTOMER_CONTEXT.Provider>
    );
}
